<!-- 人事 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- <div
          v-if="navBarFixed4"
          :class="['row15', navBarFixed4 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/peopleIcon.png" />
            <span class="publicTotal">人事数据统计</span>
          </div>
        </div> -->
    <div class="row15">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-renshi"></i> -->
        <!-- <img src="@/assets/images/peopleIcon.png" /> -->
        <span class="publicTotal">组织数据统计</span>
      </div>
    </div>
    <div class="row16">
      <div class="col col14">
        <div class="TiT">
          员工数据统计
        </div>
        <div class="Con">
          <!-- 图表 -->
          <!-- 圆环中心大标题 圆环中心小标题 类名 颜色 data -->
          <pie-charts
            v-if="flag"
            :centerTxt="(area.clockNum + '') || '0'"
            :subTxt="'累计打卡人数'"
            :pieName="'main6'"
            :pieColor="[
              '#5ad71c',
              '#f09009',
              '#1890ff'
            ]"
            :pieData="[
              { value: area.checkLateNum, name: '考勤迟到人数' },
              { value: area.checkLeaveNum, name: '考勤早退人数' },
              { value: area.checkNormalNum, name: '考勤正常人数' }
            ]"
            :comId="comId"
          ></pie-charts>
          <div class="con">
            <ul>
              <li>
                <div>
                  <span class="tit">员工总数 (人)</span
                  ><el-button @click="
                    () => {
                      collect(
                        'employeeNum',
                        '员工总数',
                        [],
                        [
                          { name: '客服名称', colProp: ['nickName'] },
                          { name: '所属公司', colProp: ['companyName'] },
                          { name: '所属部门', colProp: ['userDepartName'] }
                        ]
                      );
                    }
                  " type="text" class="txt2">{{
                    area.employeeNum || 0
                  }}</el-button>
                </div>
                <div class="two">
                  <span class="tit">在职客服人数</span
                  ><el-button @click="
                    () => {
                      collect(
                        'workServiceNum',
                        '在职客服人数',
                        [],
                        [
                          { name: '客服名称', colProp: ['nickName'] },
                          { name: '所属公司', colProp: ['companyName'] },
                          { name: '所属部门', colProp: ['userDepartName'] }
                        ]
                      );
                    }
                  " type="text" class="txt2">{{
                    area.workCustomerNum || 0
                  }}</el-button>
                </div>
              </li>
              <li>
                <div>
                  <span class="tit">考勤正常人数&nbsp;&nbsp;&nbsp;</span
                  ><el-button @click="
                    () => {
                      collect(
                        'checkNormalNum',
                        '考勤正常人数',
                        [],
                        [
                          { name: '客服名称', colProp: ['nickName'] },
                          { name: '所属公司', colProp: ['companyName'] },
                          { name: '所属部门', colProp: ['userDepartName'] }
                        ]
                      );
                    }
                  " type="text" class="txt2">{{
                    area.checkNormalNum || 0
                  }}</el-button>
                </div>
                <div class="two">
                  <span class="tit">考勤迟到人数</span
                  ><el-button @click="
                    () => {
                      collect(
                        'checkLateNum',
                        '考勤迟到人数',
                        [],
                        [
                          { name: '客服名称', colProp: ['nickName'] },
                          { name: '所属公司', colProp: ['companyName'] },
                          { name: '所属部门', colProp: ['userDepartName'] }
                        ]
                      );
                    }
                  " type="text" class="txt4">{{
                    area.checkLateNum || 0
                  }}</el-button>
                </div>
                <div class="three">
                  <span class="tit">考勤早退人数</span
                  ><el-button @click="
                    () => {
                      collect(
                        'checkLeaveNum',
                        '考勤早退人数',
                        [],
                        [
                          { name: '客服名称', colProp: ['nickName'] },
                          { name: '所属公司', colProp: ['companyName'] },
                          { name: '所属部门', colProp: ['userDepartName'] }
                        ]
                      );
                    }
                  " type="text" class="txt3">{{
                    area.checkLeaveNum || 0
                  }}</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getReportDetail"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import pieCharts from "../children/pieCharts.vue"; //饼图
import { getCheckStatisticNum,getReportDetail } from "../../../service/home";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";


export default {
  name: "",
  components: { pieCharts,CommonSumDialog },
  props: ["comId"],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getReportDetail, // 列表接口方法
      params:{
        companyId: this.comId,
      },
      loading: false,
      flag: false,
      area: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    comId(val,oldVal){
      if(val != oldVal){
        this.params.companyId = val
      }
    }
  },
  //方法集合
  methods: {
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗
    async getArea() {
      this.loading = true;
      this.flag = false;
      const { data } = await getCheckStatisticNum({companyId: this.params.companyId});
      this.area = data;
      this.flag = true;
      this.loading = false;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.navBarWrap {
  position: fixed;
  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}
.row14,
.row16 {
  .col14 {
    padding: 32px;
    .TiT {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      margin-bottom: 32px;
    }
    .Con {
      display: flex;
      justify-content: center;
      align-items: center;
      .con {
        box-sizing: border-box;
        width: 542px;
        height: 288px;
        margin-left: 80px;
        padding: 32px;
        background: #f2f9ff;
        border-radius: 8px;
        li {
          display: flex;
          div {
            display: flex;
            flex-direction: column;
          }
          .tit {
            color: rgba(51, 51, 51, 100);
            font-size: 14px;
            margin-bottom: 16px;
          }
          /deep/.txt {
            text-decoration: underline;
            text-decoration-color: rgba(255, 77, 79, 100);
            span {
              color: rgba(255, 77, 79, 100);
              font-size: 28px;
            }
          }
          /deep/.txt2 {
            text-decoration: underline;
            text-decoration-color: rgba(24, 144, 255, 100);
            span {
              color: rgba(24, 144, 255, 100);
              font-size: 28px;
            }
          }
          /deep/.txt3 {
            text-decoration: underline;
            text-decoration-color: rgba(240, 144, 9, 100);
            span {
              color: rgba(240, 144, 9, 100);
              font-size: 28px;
            }
          }
          /deep/.txt4 {
            text-decoration: underline;
            text-decoration-color: rgba(82, 196, 26, 100);
            span {
              color: rgba(82, 196, 26, 100);
              font-size: 28px;
            }
          }
          /deep/.txt5 {
            text-decoration: underline;
            text-decoration-color: rgba(240, 144, 9, 100);
            span {
              color: rgba(240, 144, 9, 100);
              font-size: 28px;
            }
          }
          .two,
          .three {
            margin-left: 80px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}
</style>
