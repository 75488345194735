<!--  -->
<template>
  <div class="double">
    <!-- <el-tab-pane label="辛橙项目" name="first"> -->
    <el-select
      v-if="legendName == '项目抽检比例'"
      class="charts"
      v-model="value"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options1"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-if="legendName == '散号抽检比例'"
      class="charts"
      v-model="value"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options2"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <div style="display: flex;">
      <span v-if="value == '1'" class="left">
        <span>{{ left[0].name }}</span>
        <el-button
          @click="
            () => {
              collect(
                'qualityProjectShopNum',
                '参与质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },

                  {
                    name: '所属客户',
                    type: 'customerScreen',
                    modelKey: 'customerId',
                    selOpt: rolesCompany
                  }
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属平台', colProp: ['platformName'] },
                  { name: '累计质检条数', colProp: ['qualityCount'] }
                ]
              );
            }
          "
          type="text"
          class="text"
          >{{ left[0].num }}</el-button
        ><el-tooltip
          class="item"
          effect="dark"
          content="统计被质检评分的聊天条数"
          placement="bottom"
          enterable
        >
          <span
            >{{ left[1].name
            }}<img src="../../../assets/images/question.png" class="tit-img"
          /></span>
        </el-tooltip>
        <el-button
          @click="
            () => {
              collect(
                'checkNum',
                '抽检条数',
                [
                  {
                    name: '质检记录编号',
                    type: 'input',
                    modelKey: 'qualityedCode'
                  },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  {
                    name: '所属客户',
                    type: 'customerScreen',
                    modelKey: 'customerId',
                    selOpt: rolesCompany
                  }
                ],
                [
                  { name: '质检记录编号', colProp: ['qualityedCode'] },
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '评分', colProp: ['score'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          type="text"
          class="text"
          >{{ left[1].num }}</el-button
        ><el-tooltip
          class="item"
          effect="dark"
          content="统计质检评分不合格的聊天记录条数"
          placement="bottom"
          enterable
        >
          <span
            >{{ left[2].name
            }}<img src="../../../assets/images/question.png" class="tit-img"
          /></span>
        </el-tooltip>

        <el-button
          @click="
            () => {
              collect(
                'problemNum',
                '客服有问题的质检条数',
                [
                  {
                    name: '质检记录编号',
                    type: 'input',
                    modelKey: 'qualityedCode'
                  },
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  {
                    name: '所属客户',
                    type: 'customerScreen',
                    modelKey: 'customerId',
                    selOpt: rolesCompany
                  },
                  { name: '受检客服', type: 'input', modelKey: 'serviceName' }
                ],
                [
                  { name: '质检记录编号', colProp: ['qualityedCode'] },
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '评分', colProp: ['score'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属公司', colProp: ['companyName'] },
                  { name: '受检客服', colProp: ['serviceName'] }
                ]
              );
            }
          "
          type="text"
          class="text"
          >{{ left[2].num }}</el-button
        >
      </span>
      <div v-show="value == '1'" :id="doubleMain" class="doubleMain"></div>
    </div>
    <!-- 表格 -->
    <table v-if="value == '2' && tableData" class="tradeData-table">
      <tbody>
        <tr>
          <th>首响(s)</th>
          <th>平响(s)</th>
          <th>问答比</th>
          <th>邀评比</th>
        </tr>
        <tr>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '首响',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '首响', colProp: ['firstTime'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.firstTime || "0" }}</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '平响',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '平响', colProp: ['avgTime'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.avgTime || "0" }}</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '问答比',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '问答比', colProp: ['qaRate'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.qaRate || "0" }}%</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '邀评比',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '邀评比', colProp: ['inviteComments'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.inviteComments || "0" }}%</el-button
            >
          </td>
        </tr>
        <tr>
          <th>转化率</th>
          <th>3分钟回复率</th>
          <th>5分钟回复率</th>
          <th>客户满意度</th>
        </tr>
        <tr>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '转化率',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '转化率', colProp: ['conversionRate'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.conversionRate || "0" }}%</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '3分钟回复率',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      {
                        name: '3分钟回复率',
                        colProp: ['threeMinuteResponseRate']
                      }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.threeMinuteResponseRate || "0" }}%</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '5分钟回复率',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      {
                        name: '5分钟回复率',
                        colProp: ['fiveMinuteResponseRate']
                      }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.fiveMinuteResponseRate || "0" }}%</el-button
            >
          </td>
          <td>
            <el-button
              @click="
                () => {
                  collect(
                    'shopServiceNum',
                    '客户满意度',
                    [
                      {
                        name: '客服名称',
                        type: 'input',
                        modelKey: 'adminName'
                      },
                      { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                      {
                        name: '所属客户',
                        type: 'customerScreen',
                        modelKey: 'customerId',
                        selOpt: rolesCompany
                      }
                    ],
                    [
                      { name: '客服名称', colProp: ['adminName'] },
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '子账号', colProp: ['subAccountName'] },
                      { name: '所属平台', colProp: ['loginPlatform'] },
                      { name: '客户满意度', colProp: ['satisfaction'] }
                    ],
                    [this.fatherDate[0], this.fatherDate[1]]
                  );
                }
              "
              class="textbtn"
              type="text"
              >{{ tableData.satisfaction || "0" }}</el-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- 暂无 -->
    <el-empty
      v-show="legendName=='项目抽检比例'&&(value == '2' && !(tableData && Object.keys(tableData).length))"
      class="nulltop"
      :image="nullImg"
      description="暂无项目质检相关数据统计"
    ></el-empty>
    <!-- 暂无 -->
    <el-empty
      v-show="legendName=='散号抽检比例'&&(value == '2' && !(tableData && Object.keys(tableData).length))"
      class="nulltop"
      :image="nullImg"
      description="暂无散号质检相关数据统计"
    ></el-empty>

    <!-- </el-tab-pane> -->
    <!-- <el-tab-pane label="阿迪项目" name="second">222</el-tab-pane> -->
    <!-- </el-tabs> -->

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getQualityReportList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import { getQualityReportList } from "../../../service/home";
import { Config } from "../../../utils/index.js";
import nullImg from "@/assets/images/xiangmu.png";
import elementResizeDetectorMaker from "element-resize-detector"; // 尺寸监听组件

export default {
  name: "",
  components: { CommonSumDialog },
  props: [
    "doubleMain",
    "left",
    "legendName",
    "doubleData",
    "fatherDate",
    "comId",
    "activeRadio"
  ],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getQualityReportList, // 列表接口方法
      activeName: "first",
      value: "1",
      // 下拉切换 === 项目
      options1: [
        {
          value: "1",
          label: "项目抽检数据比例"
        },
        {
          value: "2",
          label: "项目店铺服务分析"
        }
      ],
      // 下拉切换 === 散号
      options2: [
        {
          value: "1",
          label: "散号抽检数据比例"
        },
        {
          value: "2",
          label: "散号店铺服务分析"
        }
      ],
      // 图表配置
      option: {
        legend: {
          data: [this.legendName, "客服有问题的质检条数占比"],
          right: "80"
        },
        tooltip: { trigger: "axis" },
        color: ["#F09009", "#52c31a"], //图例颜色
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //Y轴刻度坐标文字样式
            textStyle: {
              fontSize: 14,
              color: "#999"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            //---坐标轴 刻度
            show: false //---是否显示
          },
          axisLine: {
            //隐藏刻度线
            show: false
          },
          axisLabel: {
            //Y轴刻度坐标文字样式
            textStyle: {
              fontSize: 14,
              color: "#999"
            },
            formatter: "{value} %"
          }
        },
        grid: {
          left: "9%",
          right: "9%",
          bottom: "9%",
          containLabel: false
        },
        series: [
          {
            name: this.legendName,
            data: [],
            type: "line",
            smooth: true,
            // symbol: "none", //取消折点圆圈
            lineStyle: {
              color: "#F09009" //改变折线颜色
            }
          },
          {
            name: "客服有问题的质检条数占比",
            data: [],
            type: "line",
            smooth: true,
            // symbol: "none", //取消折点圆圈
            lineStyle: {
              color: "#52c31a" //改变折线颜色
            }
          }
        ]
      },
      tableData: this.doubleData.shopOrder, //表格
      rolesCompany: this.$sto.get(Config.constants.userInfo),
      nullImg
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 数据汇总弹窗-start
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // cusType*********
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.comId,
        "",
        "",
        "",
        this.fatherDate,
        this.legendName == "散号抽检比例"
          ? "0" : this.activeRadio
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end

    handleClick(tab) {
      console.log(tab);
    },
    drawLineCharts() {
      let x = this.option.xAxis.data;
      let seriesData = this.option.series;

      this.doubleData.projectOrder.map((item) => {
        x.push(item.day);
        seriesData[0].data.push(item.checkProportion);
        seriesData[1].data.push(item.problemProportion);
      });

      let myChart = this.$echarts.init(
        document.getElementById(this.doubleMain)
      );
      myChart.setOption(this.option);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.drawLineCharts();

    var mainChart = document.getElementById(this.doubleMain);
    let charts = this.$echarts.init(mainChart); // 图标ID初始化--使用它的resize()
    // 初始化element-resize-detector组件
    let erd = elementResizeDetectorMaker({
      strategy: "scroll", // <- 推荐监听滚动，提升性能
      callOnAdd: true // 添加侦听器时是否应调用,默认true
    });
    erd.listenTo(mainChart, function() {
      // console.log(element,'........');
      charts.resize(); // 当元素尺寸发生改变是会触发此事件，刷新图表
    });
  }
};
</script>

<style lang="less" scoped>
.double {
  .charts {
    margin-bottom: 16px;
    width: 160px;
    /deep/.el-input__inner {
      color: #1890ff;
      background: #f2f9ff;
      border: none;
    }
    /deep/ .el-select__caret {
      color: #1890ff;
    }
  }
  .tradeData-table {
    margin: 16px 0;
    display: table;
    width: 100%;
    border-collapse: collapse;
    td,
    th {
      text-align: center;
      border: 1px solid #c9c9c9;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
    }
    th {
      background: #f2f9ff;
      height: 80px;
      line-height: 80px;
      font-weight: 500;
    }
    td {
      height: 80px;
      line-height: 80px;
      /deep/.textbtn {
        text-decoration: underline;
        text-decoration-color: #1890ff;
        span {
          font-size: 28px;
          color: #1890ff;
        }
      }
    }
  }
  .nulltop {
    margin-top: 66px;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 240px;
    margin-right: 32px;
    padding: 32px;
    background: #f2f9ff;
    border-radius: 8px;
    /deep/.text {
      text-align: left;
      text-decoration: underline;
      margin-bottom: 32px;

      span {
        color: rgba(24, 144, 255, 100);
        font-size: 28px;
      }
    }
  }
  .doubleMain {
    width: 856px;
    height: 330px;
  }
  .tit-img {
    margin-left: 4px;
    vertical-align: middle;
  }
  //   /deep/ .el-tab-pane {
  //     display: flex;
  //   }
  //   /deep/.el-tabs__nav-wrap {
  //     background: transparent !important;
  //   }
  //   /deep/ .el-tabs__nav {
  //     width: 200px !important;
  //     height: 40px;
  //     padding: 0 4px;
  //     line-height: 40px;
  //     border: none;
  //   }
  //   /deep/ .is-active {
  //     background: #fff;
  //     background-size: 50%;
  //   }
  //   /deep/ .el-tabs__item {
  //     text-align: center;
  //     width: 50%;
  //     height: 30px;
  //     line-height: 30px;
  //   }
  //   /deep/ .el-tabs__header {
  //     margin-bottom: 32px;
  //   }
  //   /deep/ .is-active {
  //     border: none;
  //     .el-radio-button__inner {
  //       border: none;
  //       color: #1890ff !important;
  //     }
  //   }
}
</style>
