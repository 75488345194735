<!-- 财务 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- <div
          v-if="navBarFixed3"
          :class="['row12', navBarFixed3 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/money.png" />
            <span class="publicTotal">财务数据统计</span>
          </div>
        </div> -->
    <div class="row12">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-caiwu"></i> -->
        <!-- <img src="@/assets/images/money.png" /> -->
        <span class="publicTotal">财务数据统计</span>
      </div>
    </div>
    <div class="row13">
      <div v-if="area && Object.values(area).length" class="col col13">
        <bar-charts
          v-if="flag"
          :homeData="area"
          :options1="options2"
          :barMain="['barMain2','barMain3']"
          :rightData="area"
          :comId="comId"
        ></bar-charts>
      </div>
      <div v-else class="col col5-1">
        <el-empty :image="nullImg" description="暂无财务项目数据统计"></el-empty>
      </div>
    </div>
    <div class="row14">
      <div class="col col14">
        <div class="TiT">
          订单数据统计
        </div>
        <div class="Con">
          <!-- 图表 -->
          <!-- 圆环中心大标题 圆环中心小标题 类名 颜色 data -->
          <pie-charts
            v-if="flag"
            :centerTxt="`￥${area.totalPrice}`"
            :subTxt="'订单应收总金额'"
            :pieName="'main5'"
            :pieColor="['rgb(82, 196, 26)', 'rgb(240, 144, 9)']"
            :pieData="[
              { value: area.collectionPrice, name: '订单实收金额' },
              { value: area.uncollectedPrice, name: '订单未收金额' }
            ]"
            :comId="comId"
          ></pie-charts>
          <div class="con">
            <ul>
              <li>
                <div>
                  <span class="tit">历史欠款金额</span
                  ><el-button
                    @click="
                      () => {
                        collect(
                          'historyUncollectedPrice',
                          '历史欠款金额',
                          [
                            {
                              name: '订单编号',
                              type: 'input',
                              modelKey: 'orderNo'
                            },
                            {
                              name: '客户名称',
                              type: 'input',
                              modelKey: 'customerName'
                            }
                          ],
                          [
                            { name: '订单编号', colProp: ['orderNo'] },
                            { name: '订单名称', colProp: ['orderName'] },
                            { name: '客户名称', colProp: ['customerName'] },
                            {
                              name: '服务时间',
                              colProp: ['startTime', 'endTime']
                            },
                            { name: '历史欠款金额', colProp: ['unCollectionPrice'] },
                            { name: '销售负责人', colProp: ['createName'] },
                            { name: '备注', colProp: ['note'] }
                          ]
                        );
                      }
                    "
                    type="text"
                    class="txt"
                    >￥{{ area.historyUncollectedPrice || 0 }}</el-button
                  >
                </div>
                <div class="two">
                  <span class="tit">产能金额</span
                  ><el-button
                    @click="
                      () => {
                        collect(
                          'capacityPrice',
                          '产能金额',
                          [
                            {
                              name: '订单编号',
                              type: 'input',
                              modelKey: 'orderNo'
                            },
                            {
                              name: '客户名称',
                              type: 'input',
                              modelKey: 'customerName'
                            }
                          ],
                          [
                            { name: '订单编号', colProp: ['orderNo'] },
                            { name: '订单名称', colProp: ['orderName'] },
                            { name: '客户名称', colProp: ['customerName'] },
                            {
                              name: '服务时间',
                              colProp: ['startTime', 'endTime']
                            },
                            { name: '应收金额', colProp: ['price'] },
                            { name: '销售负责人', colProp: ['createName'] },
                            { name: '备注', colProp: ['note'] }
                          ]
                        );
                      }
                    "
                    type="text"
                    class="txt2"
                    >￥{{ area.capacityPrice || 0 }}</el-button
                  >
                </div>
              </li>
              <li>
                <div>
                  <span class="tit">订单未收金额</span
                  ><el-button
                    @click="
                      () => {
                        collect(
                          'uncollectedPrice',
                          '订单未收金额',
                          [
                            {
                              name: '订单编号',
                              type: 'input',
                              modelKey: 'orderNo'
                            },
                            {
                              name: '客户名称',
                              type: 'input',
                              modelKey: 'customerName'
                            }
                          ],
                          [
                            { name: '订单编号', colProp: ['orderNo'] },
                            { name: '订单名称', colProp: ['orderName'] },
                            { name: '客户名称', colProp: ['customerName'] },
                            {
                              name: '服务时间',
                              colProp: ['startTime', 'endTime']
                            },
                            { name: '未收金额', colProp: ['unCollectionPrice'] },
                            { name: '销售负责人', colProp: ['createName'] },
                            { name: '备注', colProp: ['note'] }
                          ]
                        );
                      }
                    "
                    type="text"
                    class="txt3"
                    >￥{{ area.uncollectedPrice || 0 }}</el-button
                  >
                </div>
                <div class="two">
                  <span class="tit">订单实收金额</span
                  ><el-button
                    @click="
                      () => {
                        collect(
                          'collectionPrice',
                          '订单实收金额',
                          [
                            {
                              name: '订单编号',
                              type: 'input',
                              modelKey: 'orderNo'
                            },
                            {
                              name: '客户名称',
                              type: 'input',
                              modelKey: 'customerName'
                            }
                          ],
                          [
                            { name: '订单编号', colProp: ['orderNo'] },
                            { name: '订单名称', colProp: ['orderName'] },
                            { name: '客户名称', colProp: ['customerName'] },
                            {
                              name: '服务时间',
                              colProp: ['startTime', 'endTime']
                            },
                            { name: '实收金额', colProp: ['collectionAmount'] },
                            { name: '销售负责人', colProp: ['createName'] },
                            { name: '备注', colProp: ['note'] }
                          ]
                        );
                      }
                    "
                    type="text"
                    class="txt4"
                    >￥{{ area.collectionPrice || 0 }}</el-button
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getFinanceReportDetail
      "
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import barCharts from "../children/barCharts.vue"; //柱图
import pieCharts from "../children/pieCharts.vue"; //饼图
import { getFinanceStatisticNum,getFinanceReportDetail } from "../../../service/home";
import CommonSumDialog from "../../../components/common/commonSumDialog.vue";
import nullImg from "@/assets/images/line.png"

export default {
  name: "",
  components: { barCharts, pieCharts,CommonSumDialog },
  props: ["comId"],
  data() {
    return {
      nullImg,
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getFinanceReportDetail, // 列表接口方法
      options2: [
        {
          value: "1",
          label: "营业利润比"
        },
        {
          value: "2",
          label: "营业支出类型占比"
        }
      ],
      params:{
        companyId: this.comId,
      },
      loading: false,
      flag: false,
      area: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    comId(val,oldVal){
      if(val != oldVal){
        this.params.companyId = val
      }
    }
  },
  //方法集合
  methods: {
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗
    async getArea() {
      this.loading = true;
      this.flag = false;
      const { data } = await getFinanceStatisticNum({companyId: this.params.companyId});
      console.log(data);
      this.area = data;
      this.flag = true;
      this.loading = false;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.navBarWrap {
  position: fixed;
  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}

.row13 {
  margin: 16px 0;
  .col13 {
    padding: 32px;
  }
}
.row14 {
  .col14 {
    padding: 32px;
    .TiT {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      margin-bottom: 32px;
    }
    .Con {
      display: flex;
      justify-content: center;
      align-items: center;
      .con {
        box-sizing: border-box;
        width: 650px;
        height: 288px;
        margin-left: 80px;
        padding: 32px;
        background: #f2f9ff;
        border-radius: 8px;
        li {
          display: flex;
          div {
            display: flex;
            flex-direction: column;
            width: 49%;
            text-align: left;
          }
          .tit {
            color: rgba(51, 51, 51, 100);
            font-size: 14px;
            margin-bottom: 16px;
          }
          /deep/.txt {
            text-decoration: underline;
            text-decoration-color: rgba(255, 77, 79, 100);
            text-align: left;
            span {
              color: rgba(255, 77, 79, 100);
              font-size: 28px;
            }
          }
          /deep/.txt2 {
            text-decoration: underline;
            text-decoration-color: rgba(24, 144, 255, 100);
            text-align: left;
            span {
              color: rgba(24, 144, 255, 100);
              font-size: 28px;
            }
          }
          /deep/.txt3 {
            text-decoration: underline;
            text-decoration-color: rgba(240, 144, 9, 100);
            text-align: left;
            span {
              color: rgba(240, 144, 9, 100);
              font-size: 28px;
            }
          }
          /deep/.txt4 {
            text-decoration: underline;
            text-decoration-color: rgba(82, 196, 26, 100);
            text-align: left;
            span {
              color: rgba(82, 196, 26, 100);
              font-size: 28px;
            }
          }
          /deep/.txt5 {
            text-decoration: underline;
            text-decoration-color: rgba(240, 144, 9, 100);
            text-align: left;
            span {
              color: rgba(240, 144, 9, 100);
              font-size: 28px;
            }
          }
          .two,
          .three {
            // margin-left: 80px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}
</style>
