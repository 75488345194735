var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_vm._m(0),_c('div',{staticClass:"row13"},[(_vm.area && Object.values(_vm.area).length)?_c('div',{staticClass:"col col13"},[(_vm.flag)?_c('bar-charts',{attrs:{"homeData":_vm.area,"options1":_vm.options2,"barMain":['barMain2','barMain3'],"rightData":_vm.area,"comId":_vm.comId}}):_vm._e()],1):_c('div',{staticClass:"col col5-1"},[_c('el-empty',{attrs:{"image":_vm.nullImg,"description":"暂无财务项目数据统计"}})],1)]),_c('div',{staticClass:"row14"},[_c('div',{staticClass:"col col14"},[_c('div',{staticClass:"TiT"},[_vm._v(" 订单数据统计 ")]),_c('div',{staticClass:"Con"},[(_vm.flag)?_c('pie-charts',{attrs:{"centerTxt":("￥" + (_vm.area.totalPrice)),"subTxt":'订单应收总金额',"pieName":'main5',"pieColor":['rgb(82, 196, 26)', 'rgb(240, 144, 9)'],"pieData":[
            { value: _vm.area.collectionPrice, name: '订单实收金额' },
            { value: _vm.area.uncollectedPrice, name: '订单未收金额' }
          ],"comId":_vm.comId}}):_vm._e(),_c('div',{staticClass:"con"},[_c('ul',[_c('li',[_c('div',[_c('span',{staticClass:"tit"},[_vm._v("历史欠款金额")]),_c('el-button',{staticClass:"txt",attrs:{"type":"text"},on:{"click":function () {
                      _vm.collect(
                        'historyUncollectedPrice',
                        '历史欠款金额',
                        [
                          {
                            name: '订单编号',
                            type: 'input',
                            modelKey: 'orderNo'
                          },
                          {
                            name: '客户名称',
                            type: 'input',
                            modelKey: 'customerName'
                          }
                        ],
                        [
                          { name: '订单编号', colProp: ['orderNo'] },
                          { name: '订单名称', colProp: ['orderName'] },
                          { name: '客户名称', colProp: ['customerName'] },
                          {
                            name: '服务时间',
                            colProp: ['startTime', 'endTime']
                          },
                          { name: '历史欠款金额', colProp: ['unCollectionPrice'] },
                          { name: '销售负责人', colProp: ['createName'] },
                          { name: '备注', colProp: ['note'] }
                        ]
                      );
                    }}},[_vm._v("￥"+_vm._s(_vm.area.historyUncollectedPrice || 0))])],1),_c('div',{staticClass:"two"},[_c('span',{staticClass:"tit"},[_vm._v("产能金额")]),_c('el-button',{staticClass:"txt2",attrs:{"type":"text"},on:{"click":function () {
                      _vm.collect(
                        'capacityPrice',
                        '产能金额',
                        [
                          {
                            name: '订单编号',
                            type: 'input',
                            modelKey: 'orderNo'
                          },
                          {
                            name: '客户名称',
                            type: 'input',
                            modelKey: 'customerName'
                          }
                        ],
                        [
                          { name: '订单编号', colProp: ['orderNo'] },
                          { name: '订单名称', colProp: ['orderName'] },
                          { name: '客户名称', colProp: ['customerName'] },
                          {
                            name: '服务时间',
                            colProp: ['startTime', 'endTime']
                          },
                          { name: '应收金额', colProp: ['price'] },
                          { name: '销售负责人', colProp: ['createName'] },
                          { name: '备注', colProp: ['note'] }
                        ]
                      );
                    }}},[_vm._v("￥"+_vm._s(_vm.area.capacityPrice || 0))])],1)]),_c('li',[_c('div',[_c('span',{staticClass:"tit"},[_vm._v("订单未收金额")]),_c('el-button',{staticClass:"txt3",attrs:{"type":"text"},on:{"click":function () {
                      _vm.collect(
                        'uncollectedPrice',
                        '订单未收金额',
                        [
                          {
                            name: '订单编号',
                            type: 'input',
                            modelKey: 'orderNo'
                          },
                          {
                            name: '客户名称',
                            type: 'input',
                            modelKey: 'customerName'
                          }
                        ],
                        [
                          { name: '订单编号', colProp: ['orderNo'] },
                          { name: '订单名称', colProp: ['orderName'] },
                          { name: '客户名称', colProp: ['customerName'] },
                          {
                            name: '服务时间',
                            colProp: ['startTime', 'endTime']
                          },
                          { name: '未收金额', colProp: ['unCollectionPrice'] },
                          { name: '销售负责人', colProp: ['createName'] },
                          { name: '备注', colProp: ['note'] }
                        ]
                      );
                    }}},[_vm._v("￥"+_vm._s(_vm.area.uncollectedPrice || 0))])],1),_c('div',{staticClass:"two"},[_c('span',{staticClass:"tit"},[_vm._v("订单实收金额")]),_c('el-button',{staticClass:"txt4",attrs:{"type":"text"},on:{"click":function () {
                      _vm.collect(
                        'collectionPrice',
                        '订单实收金额',
                        [
                          {
                            name: '订单编号',
                            type: 'input',
                            modelKey: 'orderNo'
                          },
                          {
                            name: '客户名称',
                            type: 'input',
                            modelKey: 'customerName'
                          }
                        ],
                        [
                          { name: '订单编号', colProp: ['orderNo'] },
                          { name: '订单名称', colProp: ['orderName'] },
                          { name: '客户名称', colProp: ['customerName'] },
                          {
                            name: '服务时间',
                            colProp: ['startTime', 'endTime']
                          },
                          { name: '实收金额', colProp: ['collectionAmount'] },
                          { name: '销售负责人', colProp: ['createName'] },
                          { name: '备注', colProp: ['note'] }
                        ]
                      );
                    }}},[_vm._v("￥"+_vm._s(_vm.area.collectionPrice || 0))])],1)])])])],1)])]),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.getFinanceReportDetail,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row12"},[_c('div',{staticClass:"col publicColTit"},[_c('span',{staticClass:"publicTotal"},[_vm._v("财务数据统计")])])])}]

export { render, staticRenderFns }