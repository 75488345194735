<!--  -->
<template>
  <div class="see">
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="visible"
      width="30%"
      :before-close="handleClose"
      append-to-body
    >
      <el-table v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-if="title=='最新动态'" :data="infoDate" style="width: 100%">
        <el-table-column
          v-for="(item, i) in childColName"
          :key="i"
          :label="item.name"
          :formatter="tableColumn"
        >
          <template slot-scope="scope">
            <span v-if="item.colProp && item.colProp.length"
              ><span>{{ scope.row[item.colProp[0]] }}</span></span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-else :data="infoDate" style="width: 100%">
        <el-table-column
          v-for="(item, i) in childColName1"
          :key="i"
          :label="item.name"
          :formatter="tableColumn"
        >
          <template slot-scope="scope">
            <span v-if="item.colProp && item.colProp.length"
              ><span>{{ scope.row[item.colProp[0]] }}</span></span
            >
          </template>
        </el-table-column>
      </el-table>
      <br/>
      <el-pagination
        @size-change="(e) => pageFun(e, 'pageSize')"
        @current-change="(e) => pageFun(e)"
        style="text-align: center"
        :page-sizes="pagination.pageSizes"
        :page-size="params.pageSize"
        :current-page="params.pageNum"
        layout="total, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
      <!-- <span v-show="title !='最新动态'&&'公告'" slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              handleClose();
            }"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              handleClose();
            }"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { tableColumn } from "../../../utils/index.js";
import { selectListByPage, getSystemNoticeByPage } from "../../../service/home";


export default {
  name: "",
  components: {},
  props: ["title", "visible","comId"],
  data() {
    return {
      tableColumn,
      childColName: [
        { name: "创建时间", colProp: ["createTime"] },
        { name: "操作人", colProp: ["userName"] },
        { name: "操作描述", colProp: ["description"] }
      ],
      childColName1: [
        { name: "标题", colProp: ["adviceTitle"] },
        { name: "创建时间", colProp: ["createTime"] }
      ],
      params: {
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      loading: false,
      infoDate: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClose() {
      this.params = {
        pageNum: 1,
        pageSize: 10
      }
      this.$emit("closeSeeDialog");
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      if(this.title =='最新动态'){
        this.getAllActions()
      }else {
        this.getAllNotice()
      }
    },
    // 查看全部动态
    async getAllActions() {
      this.loading = true
      let params = {...this.params}
      params.companyId = this.comId
      const { data } = await selectListByPage(params)
      this.infoDate = data.list
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.loading = false
    },
    // 查看全部 公告
    async getAllNotice() {
      this.loading = true
      let params = {...this.params}
      params.companyId = this.comId
      const { data } = await getSystemNoticeByPage(params)
      this.infoDate = data.list
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.loading = false
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped></style>
