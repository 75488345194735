<!-- 客服 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- <div
          v-if="navBarFixed1"
          :class="['row6', navBarFixed1 ? 'navBarWrap' : '']"
        >
          <div class="col publicColTit">
            <img src="@/assets/images/cusIcon.png" />
            <span class="publicTotal">客服数据统计</span>
          </div>
        </div> -->
    <div class="row6">
      <div class="col publicColTit">
        <!-- <i class="iconfont icon-kefu"></i> -->
        <span class="publicTotal">客服数据统计</span>
      </div>
    </div>
    <div class="row7">
      <div class="col publicBox">
        <div class="tit">
          服务中上号店铺数统计
        </div>
        <div class="con">
          <div class="pie">
            <!--echarts 图表 -->
            <!-- 圆环中心大标题 圆环中心小标题 类名 颜色 data -->
            <pie-charts
              v-if="flag"
              :centerTxt="(area2.serviceLoginShopNum +'') || '0'"
              :pieName="'main2'"
              :pieColor="['rgb(82, 196, 26)', 'rgb(240, 144, 9)']"
              :pieData="[
                {
                  value: area2.actualLoginShopNum,
                  name: '服务中实际上号店铺数'
                },
                { value: area2.unLoginShopNum, name: '服务中未上号店铺数' }
              ]"
              :comId="comId"
            ></pie-charts>
            <el-tooltip
              class="item"
              effect="dark"
              content="当日正在服务的店铺总数"
              placement="bottom"
              enterable
            >
              <span class="shopExplain"
                >服务中应上号店铺总数<img
                  src="@/assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
          </div>
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="实时同步到当日有聊天记录的店铺总数"
              placement="bottom"
              enterable
              ><span class="total"
                >服务中实际上号店铺数<img
                  src="@/assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
            <el-button @click="
                () => {
                  collect(
                    'actualLoginShopNum',
                    '服务中实际上号店铺数',
                    [
                      {
                        name: '店铺名称',
                        type: 'input',
                        modelKey: 'shopName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      },
                      {
                        name: '所属平台',
                        type: 'platSelect',
                        modelKey: 'platformId',
                        selOpt: this.platList
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '所属平台', colProp: ['platformName'] },
                      { name: '关联订单数', colProp: ['orderNum'] },
                    ]
                  );
                }
              " type="text" class="text shop2">{{
              area2.actualLoginShopNum || 0
            }}</el-button>
            <span class="total topPx">服务中未上号店铺数</span>
            <el-button @click="
                () => {
                  collect(
                    'unLoginShopNum',
                    '服务中未上号店铺数',
                    [
                      {
                        name: '店铺名称',
                        type: 'input',
                        modelKey: 'shopName'
                      },
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      },
                      {
                        name: '所属平台',
                        type: 'platSelect',
                        modelKey: 'platformId',
                        selOpt: this.platList
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '所属平台', colProp: ['platformName'] },
                      { name: '关联订单数', colProp: ['orderNum'] },
                    ]
                  );
                }
              " type="text" class="text shop3">{{
              area2.unLoginShopNum || 0
            }}</el-button>
          </div>
        </div>
      </div>
      <div class="col publicBox">
        <div class="tit">
          客服预警统计
        </div>
        <div style="display: flex;align-items: center;">
          <!-- 图表 -->
          <div class="pie">
            <!-- echarts -->
            <!-- 图表 -->
            <!-- 圆环中心大标题 圆环中心小标题 类名 颜色 data -->
            <pie-charts
              v-if="flag"
              :centerTxt="(area2.earlyWarningNum + '') || '0'"
              :pieName="'main3'"
              :pieColor="['rgb(82, 196, 26)', 'rgb(240, 144, 9)']"
              :pieData="[
                { value: area2.affirmWarningNum, name: '确认预警总数' },
                { value: area2.unAffirmWarningNum, name: '未确认预警总数' }
              ]"
              :comId="comId"
            ></pie-charts>
            <el-tooltip
              class="item"
              effect="dark"
              content="当前客服聊天超时预警总数"
              placement="bottom"
              enterable
            >
              <span class="shopExplain"
                >当前预警<br />总数<img
                  src="@/assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
          </div>
          <div
            class="year"
            style="display: flex;flex-direction: column;justify-content: center;"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="当前客服聊天超时预警已处理的总数"
              placement="bottom"
              enterable
              ><span class="total"
                >确认预警总数
                <img src="@/assets/images/question.png" class="tit-img"/></span
            ></el-tooltip>
            <el-button @click="
                () => {
                  collect(
                    'affirmWarningNum',
                    '确认预警总数',
                    [
                      {
                  name: '预警编号',
                  type: 'input',
                  modelKey: 'warningCode'
                },
                {
                  name: '店铺名称',
                  type: 'input',
                  modelKey: 'shopName'
                },
                {
                  name: '子账号名称',
                  type: 'input',
                  modelKey: 'subAccountName'
                },
                {
                  name: '客服所属公司',
                  type: 'input',
                  modelKey: 'serviceCompanyId'
                }
                    ],
                    [
                      { name: '预警编号', colProp: ['warningCode'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '子账号名称', colProp: ['subAccountName'] },
                { name: '负责客服', colProp: ['adminNames'] },
                { name: '客服所属公司', colProp: ['companyName'] },
                { name: '处理状态 ', colProp: ['dealStatus'] }
                    ]
                  );
                }
              " type="text" class="text shop2">{{
              area2.affirmWarningNum || 0
            }}</el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="当前客服聊天超时预警未处理的总数"
              placement="bottom"
              enterable
              ><span class="total topPx"
                >未确认预警总数<img
                  src="@/assets/images/question.png"
                  class="tit-img"/></span
            ></el-tooltip>
            <el-button @click="
                () => {
                  collect(
                    'unAffirmWarningNum',
                    '未确认预警总数',
                    [
                      {
                  name: '预警编号',
                  type: 'input',
                  modelKey: 'warningCode'
                },
                {
                  name: '店铺名称',
                  type: 'input',
                  modelKey: 'shopName'
                },
                {
                  name: '子账号名称',
                  type: 'input',
                  modelKey: 'subAccountName'
                },
                {
                  name: '客服所属公司',
                  type: 'input',
                  modelKey: 'serviceCompanyId'
                }
                    ],
                    [
                      { name: '预警编号', colProp: ['warningCode'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '子账号名称', colProp: ['subAccountName'] },
                { name: '负责客服', colProp: ['adminNames'] },
                { name: '客服所属公司', colProp: ['companyName'] },
                { name: '处理状态 ', colProp: ['dealStatus'] }
                    ]
                  );
                }
              " type="text" class="text shop3">{{
              area2.unAffirmWarningNum || 0
            }}</el-button>
          </div>
        </div>
      </div>
      <div class="col publicThreeBox">
        <div class="tit">
          客服数据概览
          <!-- <div>
                <el-radio-group v-model="label" @change="changeRadio">
                  <el-radio-button :label="0">昨日</el-radio-button>
                  <el-radio-button :label="1">今日</el-radio-button>
                </el-radio-group>
              </div> -->
        </div>
        <ul style="width: 100%;">
          <li style="display: flex;">
            <span class="everyone"
              ><el-tooltip
                class="item"
                effect="dark"
                content="桌面端在线客服总数"
                placement="bottom"
                enterable
                ><span class="sale-tit"
                  >客服总在线数<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button
                @click="
                  () => {
                    collect(
                      'serviceLoginNum',
                      '客服总在线数',
                      [
                        {
                          name: '客服名称',
                          type: 'input',
                          modelKey: 'serviceName'
                        }
                      ],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['departName'] },
                        { name: '当前带店数', colProp: ['takeNum'] },
                        { name: '当前在线数', colProp: ['onlineNum'] }
                      ]
                    );
                  }
                "
                type="text"
                class="sale-datas"
                >{{ area2.serviceLoginNum || 0 }}</el-button
              ></span
            >
            <span class="everyone"
              ><el-tooltip
                class="item"
                effect="dark"
                content="订单服务时间内还未分配明天客服的子账号"
                placement="bottom"
                enterable
                ><span class="sale-tit"
                  >子账号未分配客服预警数<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button
                @click="
                  () => {
                    collect(
                      'unServiceSubAccountNum',
                      '子账号未分配客服预警数',
                      [
                        {
                          name: '子账号名称',
                          type: 'input',
                          modelKey: 'subAccountName'
                        }
                      ],
                      [
                        { name: '子账号名称', colProp: ['subAccountName'] },
                        { name: '子账号密码', colProp: ['subaccountPassword'] },
                        { name: '指派客服', colProp: ['nickName'] },
                        { name: '所属店铺', colProp: ['shopName'] },
                        { name: '子账号服务时间', colProp: ['startTime','endTime'] }
                      ]
                    );
                  }
                "
                type="text"
                class="sale-datas"
                >{{ area2.unServiceSubAccountNum || 0 }}</el-button
              ></span
            >
          </li>
          <li style="display: flex;">
            <span class="everyone  ever2"
              ><el-tooltip
                class="item"
                effect="dark"
                content="公司内有效服务子账号总数"
                placement="bottom"
                enterable
                ><span class="sale-tit"
                  >服务时间内子账号数<img
                    src="@/assets/images/question.png"
                    class="tit-img"/></span></el-tooltip
              ><el-button
                @click="
                  () => {
                    collect(
                      'serviceSubAccountNum',
                      '服务时间内子账号数',
                      [
                        {
                          name: '子账号名称',
                          type: 'input',
                          modelKey: 'subAccountName'
                        }
                      ],
                      [
                        { name: '子账号名称', colProp: ['subAccountName'] },
                        { name: '所属客服', colProp: ['nickName'] },
                        { name: '带店店铺名称', colProp: ['shopName'] },
                        { name: '服务时间', colProp: ['startTime','endTime'] }
                      ]
                    );
                  }
                "
                type="text"
                class="sale-datas"
                >{{ area2.serviceSubAccountNum || 0 }}</el-button
              ></span
            >
            <span class="everyone every3">
              <span class="sale-tit">子账号在线数</span>
              <el-button
                @click="
                  () => {
                    collect(
                      'subAccountLoginNum',
                      '子账号在线数',
                      [
                        {
                          name: '子账号名称',
                          type: 'input',
                          modelKey: 'subAccountName'
                        }
                      ],
                      [
                        { name: '子账号名称', colProp: ['subAccountName'] },
                        { name: '所属客服', colProp: ['nickName'] },
                        { name: '带店店铺名称', colProp: ['shopName'] },
                        { name: '服务时间', colProp: ['startTime','endTime'] }
                      ]
                    );
                  }
                "
                type="text"
                class="sale-datas"
                >{{ area2.subAccountLoginNum || 0 }}</el-button
              ></span
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      v-loading="loading2"
      element-loading-text="数据较多，拼命加载中..."
      class="row8"
    >
      <div class="col">
        <div class="pit">
          <!-- <div> -->
            <line-charts
            v-if="haveData"
            :lineId="[
              'echartsLine1',
              'echartsLine2',
              'echartsLine3',
              'echartsLine4'
            ]"
            :homeData="line ? lineData : area2"
            :title="'客服服务数据统计（已上传数据）'"
            :type="childType"
            @changeDate="changeDate"
            @changeRank="changeRank"
          ></line-charts>
          <!-- </div> -->
          <div v-show="rank == 1 || rank == 4" class="top">
            <div class="publicTit tit">
              {{ rank ==1 ? "客服咨询量TOP10":(rank ==4? "客服满意度TOP10":"--")}}
            </div>
            <!-- 咨询量 -->
            <ul v-show="rank == 1">
              <li
                v-for="(item, i) in line ? lineData.consultationDescList : area2.consultationDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                ><el-progress
                  v-if="(lineData&&lineData.consultationDescList.length)||(area2&&area2.consultationDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.consultationNum / Math.max(...consultationNumList)) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  rank == 1 ? item.consultationNum : ''
                }}</span>
              </li>
            </ul>
            <!-- 满意度 -->
            <ul v-show="rank == 4">
              <li
                v-for="(item, i) in line ? lineData.satisfactionDescList : area2.satisfactionDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                >
                <el-progress
                  v-if="(lineData&&lineData.satisfactionDescList.length)||(area2&&area2.satisfactionDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.satisfaction / Math.max(...satisfactionList)) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  item.satisfaction
                }}</span>
              </li>
            </ul>
          </div>
          <div v-show="rank == 2 || rank == 3" class="top">
            <div class="publicTit tit">
              <span class="ten">客服排名TOP10</span>
              <el-radio-group v-show="rank == 2" v-model="rankType" @change="changeRankRadio">
                <el-radio-button label="1">问答比</el-radio-button>
                <el-radio-button label="2">邀评率</el-radio-button>
              </el-radio-group>
              <el-radio-group v-show="rank == 3" v-model="rankType" @change="changeRankRadio">
                <el-radio-button label="1">首响</el-radio-button>
                <el-radio-button label="2">平响</el-radio-button>
              </el-radio-group>
            </div>
            <!-- 问答 -->
            <ul v-show="rank == 2&&rankType == 1">
              <li
                v-for="(item, i) in line ? lineData.qaRateDescList: area2.qaRateDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                >
                <el-progress
                  v-if="(lineData&&lineData.qaRateDescList.length)|| (area2&&area2.qaRateDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.qaRate / Math.ceil(Math.max(...qaRateList))) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  (item.qaRate).toFixed(2) + "%"
                }}</span>
              </li>
            </ul>
            <!-- 邀评  -->
            <ul v-show="rank == 2&&rankType == 2">
              <li
                v-for="(item, i) in line? lineData.inviteCommentsDescList:area2.inviteCommentsDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                >
                <el-progress
                  v-if="(lineData&&lineData.inviteCommentsDescList.length)|| (area2&&area2.inviteCommentsDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.inviteComments / Math.ceil(Math.max(...inviteCommentsList))) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  (item.inviteComments).toFixed(2) + "%"
                }}</span>
              </li>
            </ul>
            <!-- 首响  -->
            <ul v-show="rank == 3&&rankType == 1">
              <li
                v-for="(item, i) in line ? lineData.firsrTimeDescList: area2.firsrTimeDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                >
                <el-progress
                  v-if="(lineData&&lineData.firsrTimeDescList.length)||(area2&&area2.firsrTimeDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.firsrTime / Math.max(...firstTimeList)) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  (item.firsrTime).toFixed(2) + "s"
                }}</span>
              </li>
            </ul>
            <!-- 平响  -->
            <ul v-show="rank == 3&&rankType == 2">
              <li
                v-for="(item, i) in line ? lineData.avgTimeDescList:area2.avgTimeDescList"
                :key="i"
              >
                <span :class="[i > 2 ? 'indexNum2' : 'indexNum']">{{ i + 1 }}</span>
                <span class="name">{{ item.nickName }}</span
                >
                <el-progress
                  v-if="(lineData&&lineData.avgTimeDescList.length)||(area2&&area2.avgTimeDescList.length)"
                  :color="[i > 2 ? '#999999' : '#1890ff']"
                  :text-inside="false"
                  :show-text="false"
                  :stroke-width="16"
                  :percentage="(item.avgTime / Math.max(...avgTimeList)) * 100"
                ></el-progress>
                <span :style="{ color: i > 2 ? '#999' : '#1890ff' }">{{
                  (item.avgTime).toFixed(2) + "s"
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div v-else class="pit2">
            <el-empty :image="nullImg" description="暂无客服数据"></el-empty>
        </div> -->
      </div>
    </div>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="customerUp"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import lineCharts from "../children/lineCharts.vue"; //折线图
import pieCharts from "../children/pieCharts.vue"; //饼图
import CommonSumDialog from "../../../components/common/commonSumDialog";
import {
  customerArea,
  customerUp,
  getCustomerServiceList
} from "../../../service/home";
import { getLatelyDay, DateTransform, Config } from "../../../utils/index.js";
import { getPlatList } from "../../../service/customer.js"
import nullImg from "@/assets/images/no.png"

export default {
  name: "",
  components: { lineCharts, pieCharts, CommonSumDialog },
  props: ["comId"],
  data() {
    return {
      // 汇总弹窗所需数据
      commonSumVisible: false,
      customerUp, // 列表接口方法
      Config,
      area2: "",
      lineData: "",
      flag: false, //父组件异步请求数据回来在传到子组件
      params: {
        companyId: this.comId,
        startDate: "2021-06-22",
        endDate: "2021-06-22"
      },
      datePicker: getLatelyDay(7),
      datePicker2: getLatelyDay(90),
      childType: "3",
      loading: false,
      loading2: false,
      question: null, //咨询总量
      haveData: false,
      line: false,
      platList: [], // 所属平台/类目下拉
      nullImg,
      rank: 1, //判断排行显示
      rankType: "1",
      consultationNumList:[],//	咨询量
      qaRateList:[],//问答比
      inviteCommentsList:[],//	邀评率
      firstTimeList:[],//首响时间
      avgTimeList:[],//	平响时间
      satisfactionList:[] //满意度
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    comId(val,oldVal){
      if(val != oldVal){
        this.params.companyId = val
        this.rank = "1"
        this.rankType = "1"
        this.childType = "3",
        this.datePicker2 = getLatelyDay(90)
        this.lineData = ""
        this.area2 = ""
        this.line = false
      }
    }
  },
  //方法集合
  methods: {
    // 数据汇总弹窗
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗
    async getPlatList() {
      // 所属平台/类目下拉
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    format2(percentage) {
      return percentage
        ? Number(((percentage / 100) * this.question).toFixed(2)) + "\n"
        : "0";
    },
    async getArea() {
      this.loading = true;
      this.flag = false
      this.haveData = false;
      this.question = 0;
      this.params.startDate = DateTransform(this.datePicker2[0]);
      this.params.endDate = DateTransform(this.datePicker2[1]);
      let params = { ...this.params };
      const { data } = await customerArea(params);
      this.area2 = data;
      // let consult = this.area2.consultationDesc;
      // if(consult&&consult.length){
      //   consult.forEach((item) => {
      //     this.question += item.consultationNum;
      //   });
      // }
      
      this.consultationNumList = []
      this.qaRateList = []
      this.inviteCommentsList = []
      this.firstTimeList = []
      this.avgTimeList = []
      this.satisfactionList = []
      data.consultationDescList.forEach((item2)=> {
        if(item2.consultationNum){
          this.consultationNumList.push(item2.consultationNum)
        }
      })
      data.satisfactionDescList.forEach((item2)=> {
        if(item2.satisfaction){
          this.satisfactionList.push(item2.satisfaction)
        }
      })
      data.qaRateDescList.forEach((item2)=> {
        if(item2.qaRate){
          this.qaRateList.push(item2.qaRate)
        }
      })
      data.inviteCommentsDescList.forEach((item2)=> {
        if(item2.inviteComments){
          this.inviteCommentsList.push(item2.inviteComments)
        }
      })
      data.firsrTimeDescList.forEach((item2)=> {
        if(item2.firsrTime){
          this.firstTimeList.push(item2.firsrTime)
        }
      })
      data.avgTimeDescList.forEach((item2)=> {
        if(item2.avgTime){
          this.avgTimeList.push(item2.avgTime)
        }
      })

      this.haveData = true;
      this.flag = true
      this.loading = false;
      // this.getLineChartsData()
    },
    // 获取折线图数据
    async getLineChartsData() {
      this.loading2 = true;
      this.haveData = false;
      this.question = 0;
      this.params.startDate = DateTransform(this.datePicker2[0]);
      this.params.endDate = DateTransform(this.datePicker2[1]);
      let params = { ...this.params };
      const { data } = await getCustomerServiceList(params);
      this.lineData = data;
      // let consult = this.lineData.consultationDesc;
      // consult.forEach((item) => {
      //   this.question += item.consultationNum;
      // });


      // this.consultationNumList = data.consultationDesc
      // this.qaRateList = data.qaRateDescList
      // this.inviteCommentsList = data.inviteCommentsDescList
      // this.firstTimeList = data.firsrTimeDescList
      // this.avgTimeList = data.avgTimeDescList
      // this.satisfactionList = data.satisfactionDescList
      this.consultationNumList = []
      this.qaRateList = []
      this.inviteCommentsList = []
      this.firstTimeList = []
      this.avgTimeList = []
      this.satisfactionList = []
      data.consultationDescList.forEach((item2)=> {
        if(item2.consultationNum){
          this.consultationNumList.push(item2.consultationNum)
        }
      })
      data.satisfactionDescList.forEach((item2)=> {
        if(item2.satisfaction){
          this.satisfactionList.push(item2.satisfaction)
        }
      })
      data.qaRateDescList.forEach((item2)=> {
        if(item2.qaRate){
          this.qaRateList.push(item2.qaRate)
        }
      })
      data.inviteCommentsDescList.forEach((item2)=> {
        if(item2.inviteComments){
          this.inviteCommentsList.push(item2.inviteComments)
        }
      })
      data.firsrTimeDescList.forEach((item2)=> {
        if(item2.firsrTime){
          this.firstTimeList.push(item2.firsrTime)
        }
      })
      data.avgTimeDescList.forEach((item2)=> {
        if(item2.avgTime){
          this.avgTimeList.push(item2.avgTime)
        }
      })
      this.haveData = true;
      this.line = true;
      this.loading2 = false;
    },
    changeDate(days, num) {
      this.datePicker2 = getLatelyDay(days);
      this.childType = num;
      this.rank = 1;
      this.rankType = "1";
      this.getLineChartsData();
    },
    changeRank(val){//排行数据
      this.rank = val
      this.rankType = "1"
    },
    changeRankRadio(num){
      this.rankType = num
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPlatList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
/deep/ .el-progress {
  width: 120px !important;
  margin-right: 8px;
}
.navBarWrap {
  position: fixed;
  top: 64px;
  left: 221px;
  right: 16px;
  z-index: 8;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
  .publicColTit {
    margin: 0 !important;
  }
}

/////////公用css START//////////
.col {
  background: #fff;
  border-radius: 8px;
  text-align: left;
}
.publicColTit {
  ///////////标题行**************
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 32px;
  margin: 16px 0;
}
.publicColW {
  /////////// 大列宽度 **************
  width: 1229px !important;
  margin-right: 16px;
}
.publicColW2 {
  /////////// 小列宽度 **************
  box-sizing: border-box;
  width: 440px !important;
  padding: 32px;
}
.publicBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 606px;
  padding: 32px;
  &:nth-child(2) {
    margin: 0 16px;
  }
}
.publicThreeBox {
  ////////////三个小盒子***************
  box-sizing: border-box;
  width: 440px;
  padding: 32px;
}
.pubColLine {
  display: inline-block;
  width: 1px;
  height: 18px;
  background: #999999;
  margin: -5px 8px;
}
.publicTit {
  //各平台服务中的店铺总数
  color: rgba(16, 16, 16, 100);
  font-size: 20px;
}
.publicTotal {
  //标题统计
  color: rgba(51, 51, 51, 100);
  font-size: 24px;
  // margin-left: 8px;
}
/deep/.pubTextBtn {
  span {
    text-decoration: underline;
    color: rgba(24, 144, 255, 100);
    font-size: 20px;
  }
}
/////////公用css END//////////

.active {
  color: #1890ff;
}
.iconfont {
  font-size: 32px;
  color: #1890ff;
}

.nav {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  transform: translate(0, -50%);
  width: 40px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(24, 144, 255, 50);
}
.row7 {
  display: flex;
  .tit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
  }
  .con {
    display: flex;
    align-items: center;
    /deep/.el-progress__text {
      white-space: pre;
      &::after {
        content: "当前订单目标进度";
        display: inline-block;
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
    }
    .year {
      // margin-left: 40px;
      .total {
        color: rgba(51, 51, 51, 100);
        font-size: 14px;
      }
      .money {
        margin: 16px 0 32px;
        color: rgba(51, 51, 51, 100);
        font-size: 28px;
      }
      /deep/.text {
        // margin-top: 16px;
        text-align: left;
        span {
          font-size: 28px;
        }
      }
    }
  }
  .pie {
    position: relative;
    // margin-right: 40px;
    #main2,
    #main3,
    #main4 {
      width: 288px;
      height: 288px;
    }
    .totalShop {
      cursor: pointer;
      display: inline-block;
      text-decoration: underline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      text-align: center;
      position: absolute;
      top: 100px;
      left: 45px;
      font-size: 34px;
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    .shopExplain {
      position: absolute;
      top: 133px;
      left: 76px;
      width: 89px;
      text-align: center;
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
  }
  .year {
    .total {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
    }
    .topPx {
      margin-top: 24px;
    }
    .money {
      margin: 16px 0 32px;
      color: rgba(51, 51, 51, 100);
      font-size: 28px;
    }
    .tit-img {
      margin-left: 4px;
      vertical-align: middle;
    }
    /deep/.text {
      text-align: left;
      span {
        font-size: 28px;
      }
    }
    /deep/ .shop1 {
      span {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
    /deep/ .shop2 {
      span {
        color: #52c41a;
        border-bottom: 2px solid #52c41a;
      }
    }
    /deep/ .shop3 {
      span {
        color: #ff9e09;
        border-bottom: 2px solid #ff9e09;
      }
    }
  }
  ul {
    li:nth-child(-n + 2) {
      margin-bottom: 24px;
    }
  }
  .everyone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 48%;
    // width: 33.33%;
    .sale-tit {
      color: rgba(51, 51, 51, 100);
      font-size: 14px;
      .tit-img {
        vertical-align: middle;
        margin-left: 4px;
      }
    }
    /deep/.sale-datas {
      text-decoration: underline;
      padding-bottom: 0;
      span {
        color: rgba(24, 144, 255, 100);
        font-size: 28px;
      }
    }
  }
  .ever2 {
    // width: 42%;
  }
  // .every3 {
  //   margin-left: 16px;
  // }
}
.row8 {
  margin-top: 16px;
  .col {
    padding: 32px;
    .pit {
      // width: 1170px;
      display: flex;
      justify-content: space-between;
    }
    .pit2 {
      display: flex;
      justify-content: center;
    }
    .top {
      box-sizing: border-box;
      width: 372px;
      background: #f2f9ff;
      border-radius: 8px;
      padding: 32px;
      .tit {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ten{
          display: inline-block;
          color: rgba(16, 16, 16, 100);
          font-size: 20px;
        }
        /deep/ .is-active {
          border: none;
          background-color: transparent;
          .el-radio-button__inner {
            border: none;
            color: #1890ff !important;
          }
        }
        .el-radio-button {
          height: 16px;
          &:first-child{
            border-right: 1px solid #999;
          }
        }
        /deep/.el-radio-button__inner {
          padding: 0 10px;
          border: none;
          background: transparent;
          color: #999999;
          box-shadow: none;
        }
      }
      li:nth-child(-n + 9) {
        margin-bottom: 16px;
      }
      .indexNum {
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: #fff;
        font-size: 14px;
        background: #1890ff;
        border-radius: 50%;
      }
      .indexNum2 {
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: #101010;
        font-size: 14px;
      }
      .name {
        display: inline-block;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        margin: 0 0px 0 16px;
        width: 62px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      /deep/ .el-progress {
        display: inline-block;
        width: 196px;
      }
    }
  }
}
</style>
