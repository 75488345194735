var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_vm._m(0),_c('div',{staticClass:"row10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading4),expression:"loading4"}],staticClass:"col publicBox",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"tit tit2"},[_vm._v(" 质检店铺率统计 "),_c('div',[_c('el-radio-group',{on:{"change":_vm.changeRadio0},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("今日")])],1)],1)]),_c('div',{staticClass:"con"},[_c('el-progress',{attrs:{"type":"circle","percentage":_vm.line4? _vm.area4.qualityedShopNum&&_vm.area4.shopNum ? ((_vm.area4.qualityedShopNum / _vm.area4.shopNum) * 100 > 100 ? 100 : (_vm.area4.qualityedShopNum / _vm.area4.shopNum) * 100):0
          :_vm.area.qualityedShopNum&&_vm.area.shopNum ? ((_vm.area.qualityedShopNum / _vm.area.shopNum) * 100 > 100 ? 100 : (_vm.area.qualityedShopNum / _vm.area.shopNum) * 100):0,"width":288,"stroke-width":30,"format":_vm.format}}),_c('div',{staticClass:"year",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[_c('span',{staticClass:"total"},[_vm._v("服务店铺数（淘系）")]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopNum',
                  '服务店铺数',
                  [
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },

                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属公司', colProp: ['cpmpanyName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '累计质检次数', colProp: ['qualityCount'] }
                  ],
                  [this$1.datePicker0[0],this$1.datePicker0[1]]
                );
              }}},[_vm._v(_vm._s(_vm.line4? (_vm.area4.shopNum || 0): _vm.area.shopNum || 0))]),_c('span',{staticClass:"total tot2"},[_vm._v("质检店铺数")]),_c('el-button',{staticClass:"text2",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'qualityedShopNum',
              '质检店铺数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '累计质检次数', colProp: ['qualityCount'] } ],
              [this$1.datePicker0[0],this$1.datePicker0[1]]
            )}}},[_vm._v(_vm._s(_vm.line4? (_vm.area4.qualityedShopNum || 0): (_vm.area.qualityedShopNum || 0)))])],1)],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading5),expression:"loading5"}],staticClass:"col publicBox"},[_c('div',{staticClass:"tit tit2"},[_vm._v(" 质检店铺数统计 "),_c('div',[_c('el-radio-group',{on:{"change":_vm.changeRadio00},model:{value:(_vm.label2),callback:function ($$v) {_vm.label2=$$v},expression:"label2"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("今日")])],1)],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"pie"},[(_vm.flag && _vm.flag5)?_c('pie-charts',{attrs:{"centerTxt":_vm.line5? (_vm.area5.shouldQualityShopNum + '' || '0'):(_vm.area.shouldQualityShopNum + '' || '0'),"subTxt":'应质检店铺数',"pieName":'main4',"pieColor":['rgb(82, 196, 26)', 'rgb(240, 144, 9)'],"pieData":[
              { value: _vm.line5? _vm.area5.qualityedShopNum:_vm.area.qualityedShopNum, name: '实际质检店铺数' },
              { value: _vm.line5? _vm.area5.unQualityShopNum:_vm.area.unQualityShopNum, name: '未质检店铺数' }
            ],"selectDate":[this.datePicker00[0],this.datePicker00[1]],"comId":_vm.comId}}):_vm._e()],1),_c('div',{staticClass:"year",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"每日实际质检店铺数，根据日期筛选实际质检店铺数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"total"},[_vm._v("实际质检店铺数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("@/assets/images/question.png")}})])]),_c('el-button',{staticClass:"text shop2",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'qualityedShopNum',
              '实际质检店铺数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '累计质检次数', colProp: ['qualityCount'] } ],
              [this$1.datePicker00[0],this$1.datePicker00[1]]
            )}}},[_vm._v(_vm._s(_vm.line5? (_vm.area5.qualityedShopNum || 0): (_vm.area.qualityedShopNum || 0)))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"未质检店铺数=应质检店铺数—实际质检店铺数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"total topPx"},[_vm._v("未质检店铺数"),_c('img',{staticClass:"tit-img",attrs:{"src":require("@/assets/images/question.png")}})])]),_c('el-button',{staticClass:"text shop3",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'unQualityShopNum',
              '未质检店铺数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '累计质检次数', colProp: ['qualityCount'] } ],
              [this$1.datePicker00[0],this$1.datePicker00[1]]
            )}}},[_vm._v(_vm._s(_vm.line5? (_vm.area5.unQualityShopNum || 0):(_vm.area.unQualityShopNum || 0)))])],1)])]),_c('div',{staticClass:"col publicThreeBox"},[_c('div',{staticClass:"tit"},[_vm._v(" 质检数据概览 ")]),_c('ul',{staticStyle:{"width":"100%"}},[_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone"},[_c('span',{staticClass:"sale-tit"},[_vm._v("质检人力")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'manpowerNum',
                  '质检人力',
                  [
                    {
                      name: '质检人员',
                      type: 'input',
                      modelKey: 'adminName'
                    }
                  ],
                  [
                    { name: '质检人员', colProp: ['nickName'] },
                    { name: '累计质检次数', colProp: ['qualityCount'] },
                    { name: '最近质检时间', colProp: ['qualityTime'] }
                  ]
                );
              }}},[_vm._v(_vm._s(_vm.area.manpowerNum || 0))])],1),_c('span',{staticClass:"everyone"},[_c('span',{staticClass:"sale-tit"},[_vm._v("人均质检量")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'avgQualityNum',
                  '人均质检量',
                  [
                    {
                      name: '质检人员',
                      type: 'input',
                      modelKey: 'adminName'
                    }
                  ],
                  [
                    { name: '质检人员', colProp: ['nickName'] },
                    { name: '累计质检次数', colProp: ['qualityCount'] },
                    { name: '最近质检时间', colProp: ['qualityTime'] }
                  ]
                );
              }}},[_vm._v(_vm._s(_vm.area.avgQualityNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("今日新增质检次数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'todayNum',
              '今日新增质检次数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '今日质检次数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.todayNum || 0))])],1)]),_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("昨日新增质检次数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'yesterdayNum',
              '昨日新增质检次数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '昨日质检次数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.yesterdayNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("本周新增质检次数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'weekNum',
              '本周新增质检次数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '本周质检次数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.weekNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("本月新增质检次数")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'monthNum',
              '本月新增质检次数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '本月质检次数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.monthNum || 0))])],1)]),_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("当日总咨询量")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'dayConsultationNum',
              '当日总咨询量',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属平台', colProp: ['platformName'] },
                { name: '咨询量', colProp: ['consultationNum'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.dayConsultationNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('span',{staticClass:"sale-tit"},[_vm._v("月累计咨询量")]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'monthConsultationNum',
              '月累计咨询量',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属平台', colProp: ['platformName'] },
                { name: '咨询量', colProp: ['consultationNum'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.monthConsultationNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"当天登录服务店铺的客服数","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("当日客服数量"),_c('img',{staticClass:"tit-img",attrs:{"src":require("@/assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'serviceLoginNum',
              '当日客服数量',
              [
                { name: '客服名称', type: 'input', modelKey: 'serviceName' }
              ],
              [
                { name: '客服名称', colProp: ['nickName'] },

                { name: '所属部门', colProp: ['departName'] },
                { name: '当前带店数', colProp: ['takeNum'] },
                { name: '当前在线数', colProp: ['loginNUm'] }
              ]
            )}}},[_vm._v(_vm._s(_vm.area.serviceLoginNum || 0))])],1)]),_c('li',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"everyone  ever2"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"项目超出标准的数据预警","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("项目异常数据预警"),_c('img',{staticClass:"tit-img",attrs:{"src":require("@/assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'projectAbnormalDataNum',
              '项目异常数据预警',
              [
                { name: '客服名称', type: 'input', modelKey: 'adminName' },
                { name: '店铺名称', type: 'input', modelKey: 'shopName' }
              ],
              [
                { name: '客服名称', colProp: ['adminName'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['companyName'] },
                { name: '子账号', colProp: ['subAccountName'] },
                { name: '首响', colProp: ['firstTime'] },
                { name: '平响', colProp: ['avgTime'] },
                { name: '问答比', colProp: ['qaRate'] },
                { name: '满意度', colProp: ['satisfaction'] },
                { name: '数据上传时间', colProp: ['excelDate'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.projectAbnormalDataNum || 0))])],1),_c('span',{staticClass:"everyone  ever2"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"散号超出标准的数据预警","placement":"bottom","enterable":""}},[_c('span',{staticClass:"sale-tit"},[_vm._v("散号异常数据预警"),_c('img',{staticClass:"tit-img",attrs:{"src":require("@/assets/images/question.png")}})])]),_c('el-button',{staticClass:"sale-datas",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'unProjectAbnormalDataNum',
              '散号异常数据预警',
              [
                { name: '客服名称', type: 'input', modelKey: 'adminName' },
                { name: '店铺名称', type: 'input', modelKey: 'shopName' }
              ],
              [
                { name: '客服名称', colProp: ['adminName'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['companyName'] },
                { name: '子账号', colProp: ['subAccountName'] },
                { name: '首响', colProp: ['firstTime'] },
                { name: '平响', colProp: ['avgTime'] },
                { name: '问答比', colProp: ['qaRate'] },
                { name: '满意度', colProp: ['satisfaction'] },
                { name: '数据上传时间', colProp: ['excelDate'] } ]
            )}}},[_vm._v(_vm._s(_vm.area.unProjectAbnormalDataNum || 0))])],1)])])])]),_c('div',{staticClass:"row11"},[_c('div',{staticClass:"oneCol"},[(_vm.projectData && _vm.projectData.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading2),expression:"loading2"}],staticClass:"col publicColW col11-1",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('el-tabs',{staticClass:"xincheng",on:{"tab-click":function () { _vm.getLineData() }},model:{value:(_vm.radio1),callback:function ($$v) {_vm.radio1=$$v},expression:"radio1"}},_vm._l((_vm.projectData),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.name || '--',"name":item.id + ''}})}),1),_c('el-radio-group',{staticClass:"days",on:{"change":_vm.changeRadio},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("近7天")]),_c('el-radio-button',{attrs:{"label":2}},[_vm._v("近30天")]),_c('el-radio-button',{attrs:{"label":3}},[_vm._v("近90天")])],1)],1),(_vm.flag && _vm.flag2)?_c('double-line-charts',{ref:"chartsRef",attrs:{"legendName":'项目抽检比例',"left":[
            {
              name: '参与质检店铺数',
              num: _vm.line1
                ? _vm.lineData1.qualityedShopNum
                : _vm.area.projectOrder && _vm.area.projectOrder.qualityedShopNum
            },
            {
              name: '抽检条数',
              num: _vm.line1
                ? _vm.lineData1.checkNum
                : _vm.area.projectOrder && _vm.area.projectOrder.checkNum
            },
            {
              name: '客服有问题的质检条数',
              num: _vm.line1
                ? _vm.lineData1.problemNum
                : _vm.area.projectOrder && _vm.area.projectOrder.problemNum
            }
          ],"doubleMain":'doubluMain',"doubleData":_vm.line1
              ? _vm.lineData1 : _vm.area.projectOrder,"fatherDate":[_vm.DateTransform(this.datePicker1[0]),_vm.DateTransform(this.datePicker1[1])],"comId":_vm.params.companyId,"activeRadio":_vm.radio1}}):_vm._e()],1):_c('div',{staticClass:"col publicColW col11-1",staticStyle:{"display":"flex","justify-content":"center"}},[_c('el-empty',{attrs:{"image":_vm.nullImg,"description":"暂无项目数据"}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading3),expression:"loading3"}],staticClass:"col publicColW col11-3",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"cus",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"tit"},[_vm._v("散号相关质检数据统计")]),_c('el-radio-group',{staticClass:"days",on:{"change":_vm.changeRadio2},model:{value:(_vm.type2),callback:function ($$v) {_vm.type2=$$v},expression:"type2"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("近7天")]),_c('el-radio-button',{attrs:{"label":2}},[_vm._v("近30天")]),_c('el-radio-button',{attrs:{"label":3}},[_vm._v("近90天")])],1)],1),(_vm.flag && _vm.flag3)?_c('double-line-charts',{attrs:{"legendName":'散号抽检比例',"left":[
            {
              name: '参与质检店铺数',
              num: _vm.line2
                ? _vm.lineData2.qualityedShopNum
                : _vm.area.unProjectOrder && _vm.area.unProjectOrder.qualityedShopNum
            },
            {
              name: '抽检条数',
              num: _vm.line2
                ? _vm.lineData2.checkNum
                : _vm.area.unProjectOrder && _vm.area.unProjectOrder.checkNum
            },
            {
              name: '客服有问题的质检条数',
              num: _vm.line2
                ? _vm.lineData2.problemNum
                : _vm.area.unProjectOrder && _vm.area.unProjectOrder.problemNum
            }
          ],"doubleMain":'doubluMain2',"doubleData":_vm.line2
              ? _vm.lineData2 : _vm.area.unProjectOrder,"fatherDate":[_vm.DateTransform(this.datePicker2[0]),_vm.DateTransform(this.datePicker2[1])],"comId":_vm.params.companyId}}):_vm._e()],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading6),expression:"loading6"}],staticClass:"col publicColW2 col11-2",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('div',{staticClass:"publicTit tit spacebetween"},[_vm._v(" 质检条数数据统计 "),_c('div',[_c('el-radio-group',{on:{"change":_vm.NumChangeRadio},model:{value:(_vm.label3),callback:function ($$v) {_vm.label3=$$v},expression:"label3"}},[_c('el-radio-button',{attrs:{"label":0}},[_vm._v("昨日")]),_c('el-radio-button',{attrs:{"label":1}},[_vm._v("今日")])],1)],1)]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('el-progress',{staticClass:"pro1",attrs:{"type":"circle","percentage":_vm.flag6? (_vm.area6.qualityNum&&_vm.area6.shouldQualityNum? ((_vm.area6.qualityNum / _vm.area6.shouldQualityNum) * 100 > 100 ? 100 : (_vm.area6.qualityNum / _vm.area6.shouldQualityNum) * 100): 0)
           : (_vm.area.qualityNum&&_vm.area.shouldQualityNum? ((_vm.area.qualityNum / _vm.area.shouldQualityNum) * 100 > 100 ? 100 : (_vm.area.qualityNum / _vm.area.shouldQualityNum) * 100): 0),"width":288,"stroke-width":30,"format":_vm.format}}),_c('el-progress',{staticClass:"pro2",attrs:{"type":"circle","percentage":_vm.flag6? (_vm.area6.qualityNum&&_vm.area6.readNum ?((_vm.area6.qualityNum / _vm.area6.readNum) * 100 > 100
              ? 100
              : (_vm.area6.qualityNum / _vm.area6.readNum) * 100): 0)
              :(_vm.area.qualityNum&&_vm.area.readNum ?((_vm.area.qualityNum / _vm.area.readNum) * 100 > 100
              ? 100
              : (_vm.area.qualityNum / _vm.area.readNum) * 100): 0),"width":288,"stroke-width":30,"format":_vm.format,"color":"#52c41a"}}),_c('div',{staticClass:"service"},[_c('ul',[_c('li',[_c('span',[_vm._v("应质检条数")]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'shouldQualityNum',
              '应质检条数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '应质检条数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s((_vm.flag6? _vm.area6.shouldQualityNum: _vm.area.shouldQualityNum) || 0))])],1),_c('li',[_c('span',[_vm._v("实际质检条数")]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'qualityNum',
              '实际质检条数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '实际质检条数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s((_vm.flag6? _vm.area6.qualityNum: _vm.area.qualityNum) || 0))])],1),_c('li',[_c('span',[_vm._v("未质检条数")]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'unQualityNum',
              '未质检条数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '未质检条数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s((_vm.flag6? _vm.area6.unQualityNum: _vm.area.unQualityNum) || 0))])],1),_c('li',[_c('span',[_vm._v("质检已读条数")]),_c('el-button',{staticClass:"text2",attrs:{"type":"text"},on:{"click":function () { _vm.collect(
              'readNum',
              '质检已读条数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                
                { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: _vm.rolesCompany } ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属公司', colProp: ['cpmpanyName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '质检已读条数', colProp: ['qualityCount'] } ]
            )}}},[_vm._v(_vm._s((_vm.flag6? _vm.area6.readNum: _vm.area.readNum) || 0))])],1)])])],1)])]),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.getQualityReportList,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row9"},[_c('div',{staticClass:"col publicColTit"},[_c('span',{staticClass:"publicTotal"},[_vm._v("质检数据统计")])])])}]

export { render, staticRenderFns }