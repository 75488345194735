var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"double"},[(_vm.legendName == '项目抽检比例')?_c('el-select',{staticClass:"charts",attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e(),(_vm.legendName == '散号抽检比例')?_c('el-select',{staticClass:"charts",attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e(),_c('div',{staticStyle:{"display":"flex"}},[(_vm.value == '1')?_c('span',{staticClass:"left"},[_c('span',[_vm._v(_vm._s(_vm.left[0].name))]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () {
            _vm.collect(
              'qualityProjectShopNum',
              '参与质检店铺数',
              [
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },

                {
                  name: '所属客户',
                  type: 'customerScreen',
                  modelKey: 'customerId',
                  selOpt: _vm.rolesCompany
                }
              ],
              [
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属平台', colProp: ['platformName'] },
                { name: '累计质检条数', colProp: ['qualityCount'] }
              ]
            );
          }}},[_vm._v(_vm._s(_vm.left[0].num))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"统计被质检评分的聊天条数","placement":"bottom","enterable":""}},[_c('span',[_vm._v(_vm._s(_vm.left[1].name)),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () {
            _vm.collect(
              'checkNum',
              '抽检条数',
              [
                {
                  name: '质检记录编号',
                  type: 'input',
                  modelKey: 'qualityedCode'
                },
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                {
                  name: '所属客户',
                  type: 'customerScreen',
                  modelKey: 'customerId',
                  selOpt: _vm.rolesCompany
                }
              ],
              [
                { name: '质检记录编号', colProp: ['qualityedCode'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '评分', colProp: ['score'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属公司', colProp: ['companyName'] }
              ]
            );
          }}},[_vm._v(_vm._s(_vm.left[1].num))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"统计质检评分不合格的聊天记录条数","placement":"bottom","enterable":""}},[_c('span',[_vm._v(_vm._s(_vm.left[2].name)),_c('img',{staticClass:"tit-img",attrs:{"src":require("../../../assets/images/question.png")}})])]),_c('el-button',{staticClass:"text",attrs:{"type":"text"},on:{"click":function () {
            _vm.collect(
              'problemNum',
              '客服有问题的质检条数',
              [
                {
                  name: '质检记录编号',
                  type: 'input',
                  modelKey: 'qualityedCode'
                },
                { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                {
                  name: '所属客户',
                  type: 'customerScreen',
                  modelKey: 'customerId',
                  selOpt: _vm.rolesCompany
                },
                { name: '受检客服', type: 'input', modelKey: 'serviceName' }
              ],
              [
                { name: '质检记录编号', colProp: ['qualityedCode'] },
                { name: '店铺名称', colProp: ['shopName'] },
                { name: '评分', colProp: ['score'] },
                { name: '所属客户', colProp: ['customerName'] },
                { name: '所属公司', colProp: ['companyName'] },
                { name: '受检客服', colProp: ['serviceName'] }
              ]
            );
          }}},[_vm._v(_vm._s(_vm.left[2].num))])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value == '1'),expression:"value == '1'"}],staticClass:"doubleMain",attrs:{"id":_vm.doubleMain}})]),(_vm.value == '2' && _vm.tableData)?_c('table',{staticClass:"tradeData-table"},[_c('tbody',[_vm._m(0),_c('tr',[_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '首响',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '首响', colProp: ['firstTime'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.firstTime || "0"))])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '平响',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '平响', colProp: ['avgTime'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.avgTime || "0"))])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '问答比',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '问答比', colProp: ['qaRate'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.qaRate || "0")+"%")])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '邀评比',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '邀评比', colProp: ['inviteComments'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.inviteComments || "0")+"%")])],1)]),_vm._m(1),_c('tr',[_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '转化率',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '转化率', colProp: ['conversionRate'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.conversionRate || "0")+"%")])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '3分钟回复率',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    {
                      name: '3分钟回复率',
                      colProp: ['threeMinuteResponseRate']
                    }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.threeMinuteResponseRate || "0")+"%")])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '5分钟回复率',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    {
                      name: '5分钟回复率',
                      colProp: ['fiveMinuteResponseRate']
                    }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.fiveMinuteResponseRate || "0")+"%")])],1),_c('td',[_c('el-button',{staticClass:"textbtn",attrs:{"type":"text"},on:{"click":function () {
                _vm.collect(
                  'shopServiceNum',
                  '客户满意度',
                  [
                    {
                      name: '客服名称',
                      type: 'input',
                      modelKey: 'adminName'
                    },
                    { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                    {
                      name: '所属客户',
                      type: 'customerScreen',
                      modelKey: 'customerId',
                      selOpt: _vm.rolesCompany
                    }
                  ],
                  [
                    { name: '客服名称', colProp: ['adminName'] },
                    { name: '店铺名称', colProp: ['shopName'] },
                    { name: '所属客户', colProp: ['customerName'] },
                    { name: '子账号', colProp: ['subAccountName'] },
                    { name: '所属平台', colProp: ['loginPlatform'] },
                    { name: '客户满意度', colProp: ['satisfaction'] }
                  ],
                  [this$1.fatherDate[0], this$1.fatherDate[1]]
                );
              }}},[_vm._v(_vm._s(_vm.tableData.satisfaction || "0"))])],1)])])]):_vm._e(),_c('el-empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.legendName=='项目抽检比例'&&(_vm.value == '2' && !(_vm.tableData && Object.keys(_vm.tableData).length))),expression:"legendName=='项目抽检比例'&&(value == '2' && !(tableData && Object.keys(tableData).length))"}],staticClass:"nulltop",attrs:{"image":_vm.nullImg,"description":"暂无项目质检相关数据统计"}}),_c('el-empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.legendName=='散号抽检比例'&&(_vm.value == '2' && !(_vm.tableData && Object.keys(_vm.tableData).length))),expression:"legendName=='散号抽检比例'&&(value == '2' && !(tableData && Object.keys(tableData).length))"}],staticClass:"nulltop",attrs:{"image":_vm.nullImg,"description":"暂无散号质检相关数据统计"}}),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.getQualityReportList,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("首响(s)")]),_c('th',[_vm._v("平响(s)")]),_c('th',[_vm._v("问答比")]),_c('th',[_vm._v("邀评比")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("转化率")]),_c('th',[_vm._v("3分钟回复率")]),_c('th',[_vm._v("5分钟回复率")]),_c('th',[_vm._v("客户满意度")])])}]

export { render, staticRenderFns }