var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_vm._m(0),_c('div',{staticClass:"row16"},[_c('div',{staticClass:"col col14"},[_c('div',{staticClass:"TiT"},[_vm._v(" 员工数据统计 ")]),_c('div',{staticClass:"Con"},[(_vm.flag)?_c('pie-charts',{attrs:{"centerTxt":(_vm.area.clockNum + '') || '0',"subTxt":'累计打卡人数',"pieName":'main6',"pieColor":[
            '#5ad71c',
            '#f09009',
            '#1890ff'
          ],"pieData":[
            { value: _vm.area.checkLateNum, name: '考勤迟到人数' },
            { value: _vm.area.checkLeaveNum, name: '考勤早退人数' },
            { value: _vm.area.checkNormalNum, name: '考勤正常人数' }
          ],"comId":_vm.comId}}):_vm._e(),_c('div',{staticClass:"con"},[_c('ul',[_c('li',[_c('div',[_c('span',{staticClass:"tit"},[_vm._v("员工总数 (人)")]),_c('el-button',{staticClass:"txt2",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'employeeNum',
                      '员工总数',
                      [],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['userDepartName'] }
                      ]
                    );
                  }}},[_vm._v(_vm._s(_vm.area.employeeNum || 0))])],1),_c('div',{staticClass:"two"},[_c('span',{staticClass:"tit"},[_vm._v("在职客服人数")]),_c('el-button',{staticClass:"txt2",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'workServiceNum',
                      '在职客服人数',
                      [],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['userDepartName'] }
                      ]
                    );
                  }}},[_vm._v(_vm._s(_vm.area.workCustomerNum || 0))])],1)]),_c('li',[_c('div',[_c('span',{staticClass:"tit"},[_vm._v("考勤正常人数 ")]),_c('el-button',{staticClass:"txt2",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'checkNormalNum',
                      '考勤正常人数',
                      [],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['userDepartName'] }
                      ]
                    );
                  }}},[_vm._v(_vm._s(_vm.area.checkNormalNum || 0))])],1),_c('div',{staticClass:"two"},[_c('span',{staticClass:"tit"},[_vm._v("考勤迟到人数")]),_c('el-button',{staticClass:"txt4",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'checkLateNum',
                      '考勤迟到人数',
                      [],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['userDepartName'] }
                      ]
                    );
                  }}},[_vm._v(_vm._s(_vm.area.checkLateNum || 0))])],1),_c('div',{staticClass:"three"},[_c('span',{staticClass:"tit"},[_vm._v("考勤早退人数")]),_c('el-button',{staticClass:"txt3",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'checkLeaveNum',
                      '考勤早退人数',
                      [],
                      [
                        { name: '客服名称', colProp: ['nickName'] },
                        { name: '所属公司', colProp: ['companyName'] },
                        { name: '所属部门', colProp: ['userDepartName'] }
                      ]
                    );
                  }}},[_vm._v(_vm._s(_vm.area.checkLeaveNum || 0))])],1)])])])],1)])]),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.getReportDetail,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row15"},[_c('div',{staticClass:"col publicColTit"},[_c('span',{staticClass:"publicTotal"},[_vm._v("组织数据统计")])])])}]

export { render, staticRenderFns }